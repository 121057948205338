import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  + div {\n    padding-top: ",
        "px;\n  }\n\n  + div.noPadding {\n    padding-top: ",
        "px;\n    padding-bottom: 0 !important;\n  }\n\n  @media (min-width: ",
        ") {\n    + div {\n      padding-top: ",
        "px;\n    }\n\n    + div.noPadding {\n      padding-top: ",
        "px;\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    + div {\n      padding-top: ",
        "px;\n    }\n\n    + div.noPadding {\n      padding-top: ",
        "px;\n    }\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  padding-left: ",
        ";\n  padding-top: 24px;\n  display: flex;\n  justify-content: space-between;\n  height: 46px;\n\n  @media (min-width: ",
        ") {\n    height: 40px;\n    padding-top: 32px;\n  }\n\n  @media (min-width: ",
        ") {\n    height: 72px;\n    padding-left: 80px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  div {\n    position: absolute;\n    z-index: 1;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  svg {\n    width: ",
        ";\n    height: auto;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  justify-content: space-between;\n  height: ",
        ";\n  padding-left: ",
        ";\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  svg {\n    margin: ",
        ";\n    width: ",
        ";\n    height: auto;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, pxToRem } from "@vfit/shared/themes";
export var WrapperContainerLite = styled.header.withConfig({
    componentId: "sc-3489e5c4-0"
})(_templateObject(), function(param) {
    var disablePaddingTop = param.disablePaddingTop;
    return disablePaddingTop ? 0 : "".concat(45);
}, function(param) {
    var pt = param.pt, disablePaddingTop = param.disablePaddingTop;
    return disablePaddingTop ? 0 : "".concat(pt);
}, breakpoints.tablet, function(param) {
    var disablePaddingTop = param.disablePaddingTop;
    return disablePaddingTop ? 0 : "".concat(75);
}, function(param) {
    var pt = param.pt, disablePaddingTop = param.disablePaddingTop;
    return disablePaddingTop ? 0 : "".concat(pt);
}, breakpoints.desktop, function(param) {
    var disablePaddingTop = param.disablePaddingTop;
    return disablePaddingTop ? 0 : "".concat(65);
}, function(param) {
    var pt = param.pt, disablePaddingTop = param.disablePaddingTop;
    return disablePaddingTop ? 0 : "".concat(pt);
});
export var MainContainerLite = styled.div.withConfig({
    componentId: "sc-3489e5c4-1"
})(_templateObject1(), function(param) {
    var isLite = param.isLite;
    return isLite ? "32px" : "16px";
}, breakpoints.tablet, breakpoints.desktop);
export var WrapperHeaderLite = styled.div.withConfig({
    componentId: "sc-3489e5c4-2"
})(_templateObject2());
export var LogoWrapperLite = styled.div.withConfig({
    componentId: "sc-3489e5c4-3"
})(_templateObject3(), function(param) {
    var isMobile = param.isMobile;
    if (isMobile) {
        return "32px";
    }
    return "48px";
});
// MVA on boarding
export var MainContainer = styled.header.withConfig({
    componentId: "sc-3489e5c4-4"
})(_templateObject4(), pxToRem(62), pxToRem(16));
export var LogoWrapper = styled.div.withConfig({
    componentId: "sc-3489e5c4-5"
})(_templateObject5(), pxToRem(16, "auto"), pxToRem(30));
