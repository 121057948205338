import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  height: 786px;\n  max-width: 100%;\n  border-radius: 15px;\n  margin: 0 auto;\n  background: lightgray -559.748px -0.989px / 530.809% 142.365% no-repeat;\n  @media (min-width: ",
        ") {\n    height: 728px;\n  }\n  @media (min-width: ",
        ") {\n    height: 797px;\n    width: 1280px;\n    background: lightgray -90.989px -7.584px / 134.803% 121.78% no-repeat;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  border-radius: 15px;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  color: ",
        ";\n  ",
        ";\n  z-index: 2;\n  text-align: center;\n\n  @media (min-width: ",
        ") {\n    ",
        ";\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  color: ",
        ";\n  margin: 12px 25px;\n  text-align: center;\n  z-index: 2;\n  ",
        ";\n  @media (min-width: ",
        ") {\n    ",
        ";\n    margin-top: 12px;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  margin-top: 32px;\n  margin-bottom: 56px;\n  z-index: 2;\n  text-align: center;\n  button {\n    width: 200px;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var ExternalContainer = styled.div.withConfig({
    componentId: "sc-26be8c10-0"
})(_templateObject(), breakpoints.tablet, breakpoints.desktop);
export var ImageContainer = styled.div.withConfig({
    componentId: "sc-26be8c10-1"
})(_templateObject1());
export var Title = styled.header.withConfig({
    componentId: "sc-26be8c10-2"
})(_templateObject2(), fonts.exbold, colors.$ffffff, pxToCssFont(28, 36), breakpoints.desktop, pxToCssFont(36, 48));
export var Description = styled.p.withConfig({
    componentId: "sc-26be8c10-3"
})(_templateObject3(), fonts.regular, colors.$ffffff, pxToCssFont(18, 24), breakpoints.desktop, pxToCssFont(22, 32));
export var ButtonContainer = styled.div.withConfig({
    componentId: "sc-26be8c10-4"
})(_templateObject4());
