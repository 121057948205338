import styled from 'styled-components';
import { breakpoints } from '@vfit/shared/themes';

export const PageLayout = styled.div`
  margin: 0 auto;
  padding-top: 14px !important;
  @media (min-width: ${breakpoints.tablet}) {
    padding-top: 5px !important;
  }
  @media (min-width: ${breakpoints.desktop}) {
    padding-top: 48px !important;
  }
`;

export const MainContainer = styled.div`
  height: inherit;
  transform-style: inherit;
  perspective: inherit;
  perspective-origin: inherit;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none;
  overflow-y: inherit;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`;
