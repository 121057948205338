import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: fixed;\n  top: 0;\n  left: 0;\n  z-index: 4;\n  width: 100%;\n\n  nav {\n    background-color: ",
        ";\n  }\n\n  + div {\n    padding-top: ",
        "px;\n  }\n\n  + div.noPadding {\n    padding-top: ",
        "px;\n    padding-bottom: 0 !important;\n  }\n  \n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  @keyframes fade-in {\n    from {\n      opacity: 0;\n    }\n\n    to {\n      opacity: 1;\n    }\n  }\n\n  display: block;\n  position: fixed;\n  top: 0;\n  right: 0;\n  left: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 80%);\n  z-index: -1;\n  animation-name: fade-in;\n  animation-duration: 0.3s;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import { colors } from "@vfit/shared/themes";
import styled from "styled-components";
export var WrapperContainer = styled.header.withConfig({
    componentId: "sc-db815f50-0"
})(_templateObject(), colors.$ffffff, function(param) {
    var pt = param.pt, disablePaddingTop = param.disablePaddingTop;
    return disablePaddingTop || !pt ? 0 : "".concat(pt + 40);
}, function(param) {
    var pt = param.pt, disablePaddingTop = param.disablePaddingTop;
    return disablePaddingTop || !pt ? 0 : "".concat(pt);
});
export var MenuUnderlay = styled.div.withConfig({
    componentId: "sc-db815f50-1"
})(_templateObject1());
