import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  width: fit-content;\n  padding: ",
        " ",
        ";\n  display: flex;\n  align-items: center;\n\n  border: ",
        " solid ",
        ";\n  border-radius: ",
        ";\n  & span {\n    margin-right: ",
        ";\n    font-family: ",
        ";\n    font-weight: 700;\n    ",
        "\n    color: ",
        ";\n  }\n  & svg {\n    width: ",
        ";\n    height: ",
        ";\n    color: ",
        ";\n  }\n  & path {\n    stroke: ",
        " !important;\n    stroke-width: ",
        " !important;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  color: ",
        ";\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import { colors, fonts, pxToCssFont, pxToRem } from "@vfit/shared/themes";
import styled from "styled-components";
export var Container = styled.div.withConfig({
    componentId: "sc-83b71884-0"
})(_templateObject(), pxToRem(16), pxToRem(24), pxToRem(1), function(param) {
    var darkMode = param.darkMode;
    return darkMode ? "white" : "black";
}, pxToRem(10), pxToRem(10), fonts.regular, pxToCssFont(18, 24), function(param) {
    var darkMode = param.darkMode;
    return darkMode ? colors.$ffffff : colors.$262626;
}, pxToRem(24), pxToRem(24), function(param) {
    var darkMode = param.darkMode;
    return darkMode ? colors.$ffffff : colors.$262626;
}, function(param) {
    var darkMode = param.darkMode;
    return darkMode ? colors.$ffffff : colors.$262626;
}, pxToRem(1.5));
export var AnchorLabel = styled.span.withConfig({
    componentId: "sc-83b71884-1"
})(_templateObject1(), function(param) {
    var darkMode = param.darkMode;
    return darkMode ? colors.$ffffff : colors.$262626;
});
