import React from 'react';
import { dehydrate } from 'react-query';
import { AnchorsList, CarouselStoriesExperience, Footer, Header } from '@vfit/shared/components';
import {
  CarouselStories,
  CMS_APP_CONFIG,
  FindYourStories,
  storyModalService,
  useAppCmsConfig,
} from '@vfit/stories/stories';
import { useHeader, useFooter, API } from '@vfit/shared/data-access';
import { IPageNavigation } from '@vfit/shared/models';
import { MainContainer } from '../styles/PageLayout.style';
import { ARRAY_STORIES, CAROUSEL_SLIDER, CAROUSEL_STORIES_EXP, MODAL_STORIES } from '../mock/mock';
// eslint-disable-next-line import/no-relative-packages
import prefetchAllQueries from '../../../libs/stories/stories/src/lib/utils/prefetchFS';

export default function LandingPage() {
  const pageNavigation = useAppCmsConfig(API.CMS_GET_PAGE_NAVIGATION) as IPageNavigation;
  const { header } = useHeader(pageNavigation);
  const { footer } = useFooter(pageNavigation);

  const openStoryInModal = () => {
    storyModalService.handleShowStoryModal({
      onClose: () => {},
      stories: MODAL_STORIES,
    });
  };

  return (
    <div>
      <MainContainer>
        <Header header={header} />
        <div className="noPadding">
          <p>Landing</p>
          <button type="button" onClick={openStoryInModal}>
            OPEN STORY IN MODAL
          </button>
          <div style={{ marginTop: 20 }} />
          <CarouselStories {...CAROUSEL_SLIDER} />
          <div style={{ marginTop: 20 }} />
          <CarouselStoriesExperience {...CAROUSEL_STORIES_EXP} enableAnimationBeforeScroll />
          <div style={{ marginTop: 20 }} />
          <CarouselStoriesExperience {...CAROUSEL_STORIES_EXP} enableAnimationBeforeScroll />
          <div style={{ marginTop: 20 }} />
          <CarouselStoriesExperience {...CAROUSEL_STORIES_EXP} enableAnimationBeforeScroll />
          <div style={{ marginTop: 20 }} />
          <CarouselStoriesExperience {...CAROUSEL_STORIES_EXP} enableAnimationBeforeScroll />
          <div style={{ marginTop: 20 }} />
          <CarouselStoriesExperience {...CAROUSEL_STORIES_EXP} enableAnimationBeforeScroll />
          <div style={{ marginTop: 20 }} />
          <FindYourStories storiesArticles={[...ARRAY_STORIES]} />
          <div style={{ marginTop: 20 }} />
          <AnchorsList
            title="Seleziona una categoria di tuo interesse"
            anchors={[
              { anchorLabel: 'Device', anchorLink: '' },
              { anchorLabel: 'Tutto tech', anchorLink: '' },
              { anchorLabel: 'Un mondo migliore', anchorLink: '' },
              { anchorLabel: 'Intrattenimento', anchorLink: '' },
              { anchorLabel: 'Viaggi', anchorLink: '' },
            ]}
          />
          <Footer footer={footer} />
        </div>
      </MainContainer>
    </div>
  );
}

export async function getStaticProps() {
  // TODO: retrieve ALL stories from app
  const cmsAppConfig = await prefetchAllQueries(CMS_APP_CONFIG);
  return {
    props: {
      dehydratedState: dehydrate(cmsAppConfig),
    },
  };
}

