import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  margin-top: ",
        ";\n  column-gap: ",
        ";\n  row-gap: ",
        ";\n  flex-flow: wrap;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  @media (min-width: ",
        ") {\n    gap: ",
        ";\n    margin-top: ",
        ";\n  }\n  @media (min-width: ",
        ") {\n    gap: ",
        ";\n    margin-top: ",
        ";\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  margin: ",
        ";\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  text-align: center;\n  font-family: ",
        ";\n  ",
        "\n  color: ",
        ";\n  text-align: center;\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from "@vfit/shared/themes";
import styled from "styled-components";
export var AnchorsContainer = styled.div.withConfig({
    componentId: "sc-10ee1cb5-0"
})(_templateObject(), pxToRem(32), pxToRem(16), pxToRem(24), breakpoints.desktop, pxToRem(24), pxToRem(32), breakpoints.desktop, pxToRem(32), pxToRem(48));
export var MainContainer = styled.div.withConfig({
    componentId: "sc-10ee1cb5-1"
})(_templateObject1(), pxToRem(25));
export var Title = styled.p.withConfig({
    componentId: "sc-10ee1cb5-2"
})(_templateObject2(), fonts.exbold, pxToCssFont(24, 28), function(param) {
    var darkMode = param.darkMode;
    return darkMode ? colors.$ffffff : colors.$262626;
}, breakpoints.tablet, pxToCssFont(24, 30), breakpoints.desktop, pxToCssFont(28, 36));
