var DEFAULT_SIZE = {
    default: {
        width: 296,
        height: 356
    },
    mobile: {
        width: 269,
        height: 344
    },
    tablet: {
        width: 260,
        height: 344
    }
};
var getSlideSize = function(isMobile, isTablet) {
    if (isTablet) return DEFAULT_SIZE.tablet || DEFAULT_SIZE.mobile;
    return isMobile ? DEFAULT_SIZE.mobile : DEFAULT_SIZE.default;
};
export { getSlideSize };
