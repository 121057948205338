import { useSwiper } from "swiper/react";
import { useEffect } from "react";
import { useDeviceType, useIntersectionObserver } from "@vfit/shared/hooks";
var CarouselStoriesExperienceAnimation = function(param) {
    var containerRef = param.containerRef;
    var isBigDesktop = useDeviceType().isBigDesktop;
    var sliderStoriesCarouel = useSwiper();
    var entry = useIntersectionObserver(containerRef, {
        rootMargin: "-40%"
    });
    var isVisible = !!(entry === null || entry === void 0 ? void 0 : entry.isIntersecting) && isBigDesktop;
    var checkAnimation = function() {
        if (!(sliderStoriesCarouel === null || sliderStoriesCarouel === void 0 ? void 0 : sliderStoriesCarouel.activeIndex)) {
            sliderStoriesCarouel.slideNext(2000);
        }
    };
    useEffect(function() {
        if (isVisible) {
            checkAnimation();
        }
    }, [
        isVisible
    ]);
    return null;
};
export default CarouselStoriesExperienceAnimation;
