import { IStory } from '@vfit/stories/stories';

export const ARRAY_STORIES = [
  {
    title: 'Storia 1',
    topic: 'Novità',
    creator: 'Lorenzo Tufano',
    backgroundImg: '1.jpeg',
    ellipseImg: 'vmax.jpeg',
    variant: 'small' as const,
    type: 'story' as const,
  },
  {
    title: 'Articolo 1',
    topic: 'Novità',
    creator: 'Lorenzo Tufano',
    backgroundImg: '2.jpeg',
    ellipseImg: 'vmax.jpeg',
    variant: 'small' as const,
    type: 'article' as const,
  },
  {
    title: 'Storia 2',
    topic: 'Device',
    creator: 'Lorenzo Tufano',
    backgroundImg: '1.jpeg',
    ellipseImg: 'vmax.jpeg',
    variant: 'small' as const,
    type: 'story' as const,
  },
  {
    title: 'Storia 3',
    topic: 'Device',
    creator: 'Lorenzo Tufano',
    backgroundImg: '1.jpeg',
    ellipseImg: 'vmax.jpeg',
    variant: 'small' as const,
    type: 'story' as const,
    category: {
      id: 1,
      label: 'Device',
    },
  },
  {
    title: 'Articolo 3',
    creator: 'Lorenzo Tufano',
    topic: 'Tutto tech',
    backgroundImg: '2.jpeg',
    ellipseImg: 'vmax.jpeg',
    variant: 'small' as const,
    type: 'article' as const,
  },
  {
    title: 'Storia 1',
    topic: 'Un mondo migliore',
    creator: 'Lorenzo Tufano',
    backgroundImg: '1.jpeg',
    ellipseImg: 'vmax.jpeg',
    variant: 'small' as const,
    type: 'story' as const,
  },
  {
    title: 'Articolo 1',
    creator: 'Lorenzo Tufano',
    topic: 'Novità',
    backgroundImg: '2.jpeg',
    ellipseImg: 'vmax.jpeg',
    variant: 'small' as const,
    type: 'article' as const,
  },
  {
    title: 'Storia 2',
    topic: 'Novità',
    creator: 'Lorenzo Tufano',
    backgroundImg: '1.jpeg',
    ellipseImg: 'vmax.jpeg',
    variant: 'small' as const,
    type: 'story' as const,
  },
  {
    title: 'Storia 3',
    topic: 'Intrattenimento',
    creator: 'Lorenzo Tufano',
    backgroundImg: '1.jpeg',
    ellipseImg: 'vmax.jpeg',
    variant: 'small' as const,
    type: 'story' as const,
  },
  {
    title: 'Articolo 3',
    topic: 'Viaggi',
    creator: 'Lorenzo Tufano',
    backgroundImg: '2.jpeg',
    ellipseImg: 'vmax.jpeg',
    variant: 'small' as const,
    type: 'article' as const,
  },
];

export const CAROUSEL_STORIES_EXP = {
  action: () => {
    alert('clicked');
  },
  configuration: {
    title: 'Vodafone Stories',
    description:
      'Fatti ispirare dai migliori contenuti, consigli e novità per restare sempre connessi con il mondo',
    ctaLabel: 'Entra',
    backgroundImage: 'vfB.jpg',
    isVariant: false,
  },
  storiesArticles: ARRAY_STORIES,
};

export const CAROUSEL_SLIDER = {
  title: '<p><b>Le stories</b> del momento</p>',
  storiesArticles: ARRAY_STORIES,
};

export const MODAL_STORIES: IStory[] = [
  {
    url: '5.jpeg',
    header: {
      heading: 'DEVICE',
      subheading: 'iPhone 14 Pro: scatti notturni, nuove camere e Dynamic Island',
    },
    seeMore: {
      fullName: 'Andrea Galeazzi',
      image:
        'https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/batman_hero_avatar_comics-512.png',
      text: 'Ogni anno con i nuovi iPhone la domanda è sempre la stessa: cosa cambia? Beh, questa volta la prima novità colpisce già a prima vista. Addio notch e benvenuta Dynamic Island. Beh, questa volta la prima novità colpisce già a prima vista. Addio notch e benvenuta Dynamic Island. Beh, questa volta la prima novità colpisce già a prima vista. Addio notch e benvenuta Dynamic Island.',
      hideLabel: 'Nascondi',
      seeMoreLabel: 'Leggi di più',
    },
  },
  {
    url: 'https://privati.vodafone.it/videos/hs-mobile-horizontal-summer_medium.mp4',
    type: 'video',
    header: {
      heading: 'DEVICE',
      subheading: 'iPhone 14 Pro: scatti notturni, nuove camere e Dynamic Island',
    },
    seeMore: {
      fullName: 'Andrea Galeazzi',
      image:
        'https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/batman_hero_avatar_comics-512.png',
      text: 'Ogni anno con i nuovi iPhone la domanda è sempre la stessa: cosa cambia? Beh, questa volta la prima novità colpisce già a prima vista. Addio notch e benvenuta Dynamic Island. Beh, questa volta la prima novità colpisce già a prima vista. Addio notch e benvenuta Dynamic Island. Beh, questa volta la prima novità colpisce già a prima vista. Addio notch e benvenuta Dynamic Island.',
      hideLabel: 'Nascondi',
      seeMoreLabel: 'Leggi di più',
    },
  },
  {
    url: 'https://a-wb-bs.vodafone.it/content/dam/webaem/vodafone/consumer/videos/FWA_refresh_horizontal.mp4',
    type: 'video',
    header: {
      heading: 'DEVICE',
      subheading: 'iPhone 14 Pro: scatti notturni, nuove camere e Dynamic Island',
    },
    seeMore: {
      fullName: 'Lorenzo Tufano',
      image:
        'https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/batman_hero_avatar_comics-512.png',
      text: 'Ogni anno con i nuovi iPhone la domanda è sempre la stessa: cosa cambia? Beh, questa volta la prima novità colpisce già a prima vista. Addio notch e benvenuta Dynamic Island. Beh, questa volta la prima novità colpisce già a prima vista. Addio notch e benvenuta Dynamic Island. Beh, questa volta la prima novità colpisce già a prima vista. Addio notch e benvenuta Dynamic Island.',
      hideLabel: 'Nascondi',
      seeMoreLabel: 'Leggi di più',
    },
  },
  {
    url: 'https://privati.vodafone.it/videos/hs-mobile-horizontal-summer_medium.mp4',
    type: 'video',
    header: {
      heading: 'DEVICE',
      subheading: 'iPhone 14 Pro: scatti notturni, nuove camere e Dynamic Island',
    },
    seeMore: {
      fullName: 'Andrea Galeazzi',
      image:
        'https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/batman_hero_avatar_comics-512.png',
      text: 'Ogni anno con i nuovi iPhone la domanda è sempre la stessa: cosa cambia? Beh, questa volta la prima novità colpisce già a prima vista. Addio notch e benvenuta Dynamic Island. Beh, questa volta la prima novità colpisce già a prima vista. Addio notch e benvenuta Dynamic Island. Beh, questa volta la prima novità colpisce già a prima vista. Addio notch e benvenuta Dynamic Island.',
      hideLabel: 'Nascondi',
      seeMoreLabel: 'Leggi di più',
    },
  },
  {
    url: '3.jpeg',
    header: {
      heading: 'DEVICE',
      subheading: 'iPhone 14 Pro: scatti notturni, nuove camere e Dynamic Island',
    },
    seeMore: {
      fullName: 'Lorenzo Tufano',
      image:
        'https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/batman_hero_avatar_comics-512.png',
      text: 'Ogni anno con i nuovi iPhone la domanda è sempre la stessa: cosa cambia? Beh, questa volta la prima novità colpisce già a prima vista. Addio notch e benvenuta Dynamic Island. Beh, questa volta la prima novità colpisce già a prima vista. Addio notch e benvenuta Dynamic Island. Beh, questa volta la prima novità colpisce già a prima vista. Addio notch e benvenuta Dynamic Island.',
      hideLabel: 'Nascondi',
      seeMoreLabel: 'Leggi di più',
    },
  },
  {
    url: 'https://privati.vodafone.it/videos/hs-mobile-horizontal-summer_medium.mp4',
    type: 'video',
    header: {
      heading: 'DEVICE',
      subheading: 'iPhone 14 Pro: scatti notturni, nuove camere e Dynamic Island',
    },
  },
  {
    url: '4.jpeg',
    header: {
      heading: 'DEVICE',
      subheading: 'iPhone 14 Pro: scatti notturni, nuove camere e Dynamic Island',
    },
    seeMore: {
      fullName: 'Umberto Vignotto',
      image:
        'https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/batman_hero_avatar_comics-512.png',
      text: 'Ogni anno con i nuovi iPhone la domanda è sempre la stessa: cosa cambia? Beh, questa volta la prima novità colpisce già a prima vista. Addio notch e benvenuta Dynamic Island. Beh, questa volta la prima novità colpisce già a prima vista. Addio notch e benvenuta Dynamic Island. Beh, questa volta la prima novità colpisce già a prima vista. Addio notch e benvenuta Dynamic Island.',
      hideLabel: 'Nascondi',
      seeMoreLabel: 'Leggi di più',
    },
  },
  {
    url: 'https://privati.vodafone.it/videos/hs-mobile-horizontal-summer_medium.mp4',
    type: 'video',
    header: {
      heading: 'DEVICE',
      subheading: 'iPhone 14 Pro: scatti notturni, nuove camere e Dynamic Island',
    },
  },
  {
    url: '6.jpeg',
    seeMore: {
      fullName: 'Andrea Galeazzi',
      image:
        'https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/batman_hero_avatar_comics-512.png',
      text: 'Ogni anno con i nuovi iPhone la domanda è sempre la stessa: cosa cambia? Beh, questa volta la prima novità colpisce già a prima vista. Addio notch e benvenuta Dynamic Island. Beh, questa volta la prima novità colpisce già a prima vista. Addio notch e benvenuta Dynamic Island. Beh, questa volta la prima novità colpisce già a prima vista. Addio notch e benvenuta Dynamic Island.',
      hideLabel: 'Nascondi',
      seeMoreLabel: 'Leggi di più',
    },
  },
  {
    url: 'https://privati.vodafone.it/videos/hs-mobile-horizontal-summer_medium.mp4',
    type: 'video',
    header: {
      heading: 'DEVICE',
      subheading: 'iPhone 14 Pro: scatti notturni, nuove camere e Dynamic Island',
    },
  },
];
